import { ILocation } from "@api";
import { useOutletContext } from "react-router-dom";

interface IOutletContext {
    location: ILocation;
}

export function useLocation() {
    return useOutletContext<IOutletContext>();
}
