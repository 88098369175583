import { ILocation } from "@api";
import { DashboardContainer, useOrganizations } from "@components";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import { LocationToolbar } from "./components";

const MapPage = () => {
    const { currentOrganization } = useOrganizations();
    const [selectedLocation, setSelectedLocation] = useState<ILocation>();

    if (!currentOrganization) return null;

    return (
        <DashboardContainer>
            <LocationToolbar
                organizationId={currentOrganization.id}
                selectedLocation={selectedLocation}
                setSelectedLocation={setSelectedLocation}
            />
            {selectedLocation && (
                <Outlet
                    context={{
                        location: selectedLocation,
                    }}
                />
            )}
        </DashboardContainer>
    );
};

export default MapPage;
