import LayersRoundedIcon from "@mui/icons-material/LayersRounded";
import { ICollection, ImageType } from "@api";
import React, { useEffect, useState } from "react";
import { Menu, MenuItem, Tooltip } from "@mui/material";
import { Control } from "@components";
import { useSearchParams } from "react-router-dom";

interface ILayerControlProps {
    /**
     * Collection for the location
     */
    collection: ICollection;
    /**
     * Function called when the layer is changed
     */
    handleLayerChange: (imageType: ImageType) => void;
    /**
     * Current layer
     */
    currentLayer?: ImageType;
}

/**
 * Layer control displays a menu to select the image layer
 */
export const LayerControl = ({
    collection,
    handleLayerChange,
    currentLayer,
}: ILayerControlProps) => {
    const [searchParams] = useSearchParams();
    const [imageType] = useState(searchParams.get("imageType"));
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const collectionImages = collection.images.sort((a) =>
        a.type === ImageType.RGB ? -1 : 0
    );

    const menuItems = collectionImages.map((image) => {
        // if the image is not uploaded, don't show option in menu
        if (!image.upload_complete) return null;

        return (
            <MenuItem
                key={image.type}
                onClick={() => {
                    handleLayerChange(image.type);
                    handleClose();
                }}
                selected={image.type === currentLayer}
            >
                {image.type}
            </MenuItem>
        );
    });

    // Set the layer to the selected image type if it exists in the search params
    useEffect(() => {
        if (imageType) {
            handleLayerChange(imageType as ImageType);
        }
    }, [handleLayerChange, imageType]);

    return (
        <>
            <Tooltip
                title={"Choose image layer"}
                PopperProps={{
                    disablePortal: true,
                }}
                placement="left"
            >
                <Control onClick={handleClick}>
                    <LayersRoundedIcon />
                </Control>
            </Tooltip>
            <Menu
                open={open}
                disablePortal
                onClose={handleClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                slotProps={{
                    root: { sx: { transform: "translate(-4px, 0)" } },
                }}
            >
                <MenuItem disabled>Select layer</MenuItem>
                {menuItems}
            </Menu>
        </>
    );
};
