import { getLocationCollections } from "@api";
import { useSuspenseQuery } from "@tanstack/react-query";
import { LocationMap } from "./components/location-map/LocationMap.tsx";
import { useLocation } from "./hooks/";

const Map = () => {
    const { location } = useLocation();

    const { data } = useSuspenseQuery({
        queryKey: ["location-collections", location.id],
        queryFn: () => getLocationCollections(location.id),
    });

    return <LocationMap location={location} collections={data.items} />;
};

export default Map;
