import HistoryIcon from "@mui/icons-material/History";
import { ICollection } from "@api";
import { Menu, MenuItem, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { formatDate } from "@utils";
import { Control } from "@components";

interface IHistoryControlProps {
    /**
     * Collections for the location
     */
    collections: ICollection[];
    /**
     * Current collection
     */
    currentCollection?: ICollection;
    /**
     * Set the current collection
     */
    setCollection: (collection: ICollection) => void;
}

/**
 * History control that allows the user to select a collection based on the collection date
 */
export const HistoryControl = ({
    collections,
    currentCollection,
    setCollection,
}: IHistoryControlProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    // handle click event to open the menu
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    // handle close event to close the menu
    const handleClose = () => {
        setAnchorEl(null);
    };

    // sort collections by collection date descending
    const sortedCollections = collections.sort(
        (a, b) =>
            new Date(b.collection_date).getTime() -
            new Date(a.collection_date).getTime()
    );

    // create menu items for each valid collection
    const menuItems = sortedCollections.map((collection) => {
        return (
            <MenuItem
                key={collection.id}
                selected={collection.id === currentCollection?.id}
                disabled={
                    collection.images.length === 0 ||
                    collection.images.some((image) => !image.upload_complete)
                }
                onClick={() => {
                    setCollection(collection);
                    handleClose();
                }}
            >
                {formatDate(collection.collection_date)}
            </MenuItem>
        );
    });

    return (
        <>
            <Tooltip
                title={"Choose collection date"}
                PopperProps={{
                    disablePortal: true,
                }}
                placement="left"
            >
                <Control onClick={handleClick}>
                    <HistoryIcon />
                </Control>
            </Tooltip>
            <Menu
                open={open}
                disablePortal
                onClose={handleClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                slotProps={{
                    root: { sx: { transform: "translate(-4px, 0)" } },
                }}
            >
                <MenuItem disabled>Select collection date</MenuItem>
                {menuItems}
            </Menu>
        </>
    );
};
