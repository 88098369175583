import { Card, CardContent, Stack, Typography } from "@mui/material";
import { ICollection, ImageType } from "@api";
import { formatDate } from "@utils";

interface IProps {
    collection: ICollection;
    imageType?: ImageType;
}

export const CurrentCollectionCard = ({ collection, imageType }: IProps) => {
    return (
        <Card elevation={4} sx={{ opacity: 0.95 }}>
            <CardContent>
                <Stack direction={"row"}>
                    <Typography variant={"h6"}>
                        Collection Date -{" "}
                        {formatDate(collection.collection_date)}
                    </Typography>
                </Stack>
                {imageType && (
                    <Typography variant={"body2"}>
                        Layer - {imageType}
                    </Typography>
                )}
            </CardContent>
        </Card>
    );
};
