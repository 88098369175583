import React from "react";
import {
    IconButton,
    Menu,
    MenuItem,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { ILocation } from "@api";
import { LocationEditor } from "./location-editor";

import { DeleteLocation } from "./DeleteLocation.tsx";

interface ILocationOptionsProps {
    /**
     * Handle setting the location
     */
    setLocation: (location: ILocation) => void;
    /**
     * Current selected location
     */
    location?: ILocation;
    /**
     * Organization ID
     */
    organizationId: number;
}

/**
 * Location options menu
 */
export const LocationOptions = ({
    setLocation,
    location,
    organizationId,
}: ILocationOptionsProps) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleOptionsClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            {/* Icon button to open menu */}
            <IconButton color={"primary"} onClick={handleClick} edge={"end"}>
                <MoreVertIcon />
            </IconButton>
            {/* Menu with location options */}
            <Menu anchorEl={anchorEl} open={open} onClose={handleOptionsClose}>
                <MenuItem disabled dense>
                    Location options
                </MenuItem>
                {/* on mobile, render add a location */}
                {isMobile && (
                    <LocationEditor
                        setLocation={setLocation}
                        handleOptionsClose={handleOptionsClose}
                        organizationId={organizationId}
                    />
                )}
                {/* render edit and delete location options */}
                {location && (
                    <div>
                        <LocationEditor
                            location={location}
                            setLocation={setLocation}
                            handleOptionsClose={handleOptionsClose}
                        />
                        <DeleteLocation
                            location={location}
                            handleOptionsClose={handleOptionsClose}
                        />
                    </div>
                )}
            </Menu>
        </>
    );
};
