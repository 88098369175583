import {
    IAnnotation,
    ICollection,
    IGeometryCreate,
    ILocation,
    ImageType,
} from "@api";
import { Box, Stack } from "@mui/material";
import { RefObject } from "react";
import { MapRef } from "react-map-gl";
import { IViewState } from "../../location-editor/LocationSelect.tsx";
import { CurrentCollectionCard } from "../CurrentCollectionCard.tsx";
import { Tile } from "../LocationMap.tsx";
import { DrawControl } from "./DrawControl.tsx";
import { FullscreenControl } from "./FullscreenControl.tsx";
import { GeolocateControl } from "./GeolocateControl.tsx";
import { HistoryControl } from "./HistoryControl.tsx";
import { LayerControl } from "./LayerControl.tsx";
import { LocationControl } from "./LocationControl.tsx";
import { MeasureControl } from "./MeasureControl.tsx";

interface IControlContainerProps {
    /**
     * Reference to the map
     */
    mapRef: RefObject<MapRef>;
    /**
     * Current location
     */
    location: ILocation;
    /**
     * Current view state of the map
     */
    viewState: IViewState;
    /**
     * Current collection
     */
    collection?: ICollection;
    /**
     * Collections for the location
     */
    collections: ICollection[];
    /**
     * Set the current collection
     */
    setCollection: (collection: ICollection) => void;
    /**
     * New geometry to create
     */
    newGeometry?: IGeometryCreate;
    /**
     * Set the new geometry
     */
    setNewGeometry: (geometry: IGeometryCreate | undefined) => void;
    /**
     * Tiles for the current collection
     */
    tiles?: Tile[];
    /**
     * Set the current tile
     */
    setTile: (tile: Tile) => void;
    /**
     * Current tile
     */
    tile?: Tile;
    /**
     * Annotation currently being edited
     */
    annotationEditing?: IAnnotation;
}

export const ControlContainer = ({
    mapRef,
    location,
    viewState,
    collection,
    collections,
    setCollection,
    newGeometry,
    setNewGeometry,
    tiles,
    tile,
    setTile,
    annotationEditing,
}: IControlContainerProps) => {
    // function to handle changing a tile based on the layer being passed in
    const handleLayerChange = (imageType: ImageType) => {
        if (!tiles) return;

        // find the new tile based on the image type
        const newTile = tiles.find((t) => t.imageType === imageType);

        // set the new tile if it exists
        if (newTile) {
            setTile(newTile);
        }
    };

    return (
        <Box
            display={"flex"}
            flexDirection={"column"}
            width={"100%"}
            height={"100%"}
        >
            {collection && (
                <Box position={"absolute"} top={0} p={2}>
                    <CurrentCollectionCard
                        collection={collection}
                        imageType={tile?.imageType}
                    />
                </Box>
            )}

            <Stack
                justifyContent={"space-between"}
                alignSelf={"flex-end"}
                height={"100%"}
                mb={4}
            >
                <Stack p={2} spacing={1}>
                    <FullscreenControl
                        mapRef={mapRef}
                        newGeometry={newGeometry}
                    />
                    <LocationControl
                        mapRef={mapRef}
                        location={location}
                        viewState={viewState}
                    />
                    <GeolocateControl
                        mapRef={mapRef}
                        showLocation={false}
                        viewState={viewState}
                    />
                </Stack>

                <Stack p={2} spacing={1}>
                    {collection && (
                        <>
                            <LayerControl
                                collection={collection}
                                handleLayerChange={handleLayerChange}
                                currentLayer={tile?.imageType}
                            />
                            <HistoryControl
                                collections={collections}
                                currentCollection={collection}
                                setCollection={setCollection}
                            />
                        </>
                    )}
                    <DrawControl
                        mapRef={mapRef}
                        setNewGeometry={setNewGeometry}
                        newGeometry={newGeometry}
                        annotationEditing={annotationEditing}
                    />
                    <MeasureControl mapRef={mapRef} />
                </Stack>
            </Stack>
        </Box>
    );
};
